import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Arrow from '@/assets/icons/arrow-rounded.svg';
import InternalError from '@/assets/svg/404.svg';
import {
  ErrorContentWrapper,
  ErrorCtaWrapper,
  ErrorLayout,
  ErrorMediaContainer,
  ErrorMediaWrapper,
  ErrorWrapper
} from '@/components/app/error/Error.styles';
import CustomLink from '@/components/common/customLink/CustomLink';
import { URLS } from '@/constants/global';
import { Button, ButtonAppearance, Container, H3 } from '@proprioo/salatim';

type ErrorProps = {
  message: string;
};

const Error: FC<ErrorProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper data-test="custom-error">
      <Container>
        <ErrorLayout>
          <ErrorContentWrapper>
            <H3 title={message} />
            <ErrorCtaWrapper>
              <CustomLink data-test="error-link" link={URLS.SEARCH}>
                <Button
                  appearance={ButtonAppearance.BASIC}
                  label={t('backToListingSearch')}
                  icon={<Arrow />}
                />
              </CustomLink>
            </ErrorCtaWrapper>
          </ErrorContentWrapper>
          <ErrorMediaWrapper>
            <ErrorMediaContainer>
              <InternalError />
            </ErrorMediaContainer>
          </ErrorMediaWrapper>
        </ErrorLayout>
      </Container>
    </ErrorWrapper>
  );
};

export default Error;
