import styled from 'styled-components';

import { GridWrapper } from '@/components/common/slices/Slices.styles';
import { breakpoints, colors, convertPxToRem, radius } from '@proprioo/salatim';

export const ErrorWrapper = styled.div`
  padding: ${convertPxToRem(56)} 0;

  @media (min-width: ${breakpoints.medium}px) {
    padding-top: 0;
    margin-top: -${convertPxToRem(40)};
  }

  @media (min-width: ${breakpoints.base}px) {
    margin-top: -${convertPxToRem(80)};
  }
`;

export const ErrorLayout = styled(GridWrapper)`
  position: relative;
  align-items: center;
  overflow: hidden;
  border-radius: ${radius.normal};
  background-color: ${colors.blue.base60};
  color: ${colors.dark.base};

  @media (min-width: ${breakpoints.medium}px) {
    min-height: ${convertPxToRem(360)};
  }

  @media (min-width: ${breakpoints.base}px) {
    min-height: ${convertPxToRem(420)};
  }
`;

export const ErrorMediaContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.medium}px) {
    height: 100%;
    max-width: 65%;
    min-width: ${convertPxToRem(860)};
    transform: translateX(25%);
  }

  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const ErrorMediaWrapper = styled.div`
  pointer-events: none;
  grid-column: 1 / -1;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (min-width: ${breakpoints.medium}px) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -${convertPxToRem(1)};
    left: -${convertPxToRem(1)};
  }
`;

export const ErrorContentWrapper = styled.div`
  color: ${colors.dark.base};
  padding: ${convertPxToRem(40)} ${convertPxToRem(24)};
  grid-column: 1 / -1;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.medium}px) {
    grid-column: 1 / 4;
  }

  @media (min-width: ${breakpoints.base}px) {
    grid-column: 1 / 7;
    padding: 0 ${convertPxToRem(56)};
  }
`;

export const ErrorCtaWrapper = styled.div`
  padding: ${convertPxToRem(24)} 0 0;
`;
